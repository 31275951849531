import { NgModule } from "@angular/core";
import { EngineCustomizationsModule } from "../../core/engine-customizations/engine-customizations.module";
import { MainCustomizationPanelComponent } from "./main-customization-panel.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  declarations: [
    MainCustomizationPanelComponent,
  ],
  imports: [
    SharedModule,
    EngineCustomizationsModule
  ],
  exports: [
    MainCustomizationPanelComponent
  ]
})
export class MainCustomizationPanelModule  {
}
