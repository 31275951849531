<ng-container *ngIf="viewTabs$ | async as viewTabs">
  <div *ngIf="!isCustomizationMode" class="sticky">
    <app-main-context-menu></app-main-context-menu>
    <app-main-notification-bar></app-main-notification-bar>
  </div>
  <mat-tab-group
    [selectedIndex]="viewTabs.selectedTabIndex"
    (selectedTabChange)="onSelectedViewTabChange(viewTabs, $event)">
    <mat-tab
      *ngFor="let tab of viewTabs.visibleViewTabs; let i = index;"
      label="{{ tab.label }}"
      [disabled]="(isCustomizationMode && viewTabs.selectedTabIndex != i) || !tab.isVisible"
      bodyClass="engine-view-tab">
      <ng-template matTabContent>
        <app-engine-view
          *ngIf="tab.viewId === viewTabs.entityView.view.id"
          #engineView
          [urlState]="urlState$ | async"
          [settings]="settings$ | async"
          [entityView]="viewTabs.entityView"
          [contextMenu]="gridColumnContextMenu$ | async"
          (rowDoubleClick)="onRowDoubleClick($event)"
          (lookupColumnClick)="onLookupColumnClick($event)"
          (columnResize)="onColumnResize($event)"
          (stateChange)="onStateChange($event)"
          (pageSizeChange)="onPageSizeChange($event)">
        </app-engine-view>
      </ng-template>
    </mat-tab>
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <app-engine-view-toolbar
          *ngIf="!isCustomizationMode"
          [settings]="settings$ | async"
          [viewTabs]="viewTabs.allViewTabs"
          viewTabsSelectionMode="multi"
          [globalSearch]="stateService.getViewState().globalSearch"
          (globalSearchChange)="onGlobalSearchChange($event)"
          (globalSearchVisibilityChange)="onGlobalSearchVisibilityChange($event)"
          (filtersVisibilityChange)="onFiltersVisibilityChange($event)"
          (viewTabsVisibilityChange)="onViewTabsVisibilityChange($event)"
          (refreshData)="onRefreshData()"
          (restoreSettings)="onRestoreSettings()"
          (navigateToSettings)="onNavigateToSettings()">
        </app-engine-view-toolbar>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <engine-async-widget [delay]="100" [isReady]="true"></engine-async-widget>
</ng-container>
