import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private _location: Location) {}

  syncRouting(navigationPath: string, queryParams: Params, isCustomizationMode: boolean) {
    const url = `/${navigationPath}${(isCustomizationMode ? '/customizations' : '')}${this.getQueryString(queryParams)}`;
    if (this._location.path() != url) {
      this._location.go(navigationPath, this.getQueryString(queryParams));
    }
  }

  private getQueryString(queryParams: Params): string {
    if (queryParams) {
      const keys = Object.keys(queryParams);
      return keys.length === 0
        ? ''
        : '?' + keys.map((key) => `${key}=${encodeURIComponent(queryParams[key])}`).join('&');
    }

    return '';
  }
}
