import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of, forkJoin, Observable, EMPTY } from 'rxjs';
import { map, mergeMap, withLatestFrom, filter, catchError, switchMap, first } from 'rxjs/operators';
import {
  NavigationTargetDto,
  CreateNavigationTargetCommand,
  NavigationClient,
  SearchClient,
} from '@core/services/api-clients';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import {
  AddQueryParamsToTopNavigationStackItem,
  ClearNavigationStack,
  LoadNavigationTargets,
  LoadNavigationTargetsSuccess,
  NavigateBack,
  NavigateByNavigationPath,
  NavigateByNavigationTargets,
  NavigateByRouteData,
  NavigateToHomePage,
  NavigateToTopNavigationStackItem,
  NavigateToPrevNavigationStackItem,
  NavigationActionTypes,
  PopNavigationStackItems,
  PushNavigationStackItem,
  ReplaceUrlToToken,
  SaveNavigationState,
  LoadAvailableForms,
  LoadAvailableFormsSuccess,
  ChangeSelectedForm,
  NavigateToRecentOrDefaultTarget,
} from './actions';
import {
  getCurrentNavigationPath,
  getIsDirty,
  getNavigationTargets,
  getTopNavigationStackItem,
  shouldConfirmNavigation,
} from './selectors';
import { FormOption } from './state';
import { getDefaultMenuItem, getMenuConfiguration, getRecentMenuItem, getSelectedMenuArea } from '../../menu/store/selectors';
import { SelectMenuItem } from '../../menu/store/actions';
import { RouterActions } from '@core/router/store';
import { NavigationMode } from '@core/router/store/actions';
import { IRouterStateUrl } from '@core/router/store/state';
import { ErrorsActions } from '@core/errors/store';
import { NavigationActions } from '.';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';
import { CustomizationsActions } from '../../engine-customizations/store';

@Injectable()
export class LayoutEffects {
  loadNavigationTargets$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadNavigationTargets>(NavigationActionTypes.LoadNavigationTargets),
      map((action) => action.payload.path),
      switchMap((path) => this.getNavigationTargets(path)),
      switchMap((navigationTargets) => of(new LoadNavigationTargetsSuccess({ navigationTargets }))),
    ),
  );

  loadAvailableForms$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadAvailableForms>(NavigationActionTypes.LoadAvailableForms),
      map((action) => action.payload.entityId),
      mergeMap((entityId) =>
        forkJoin([
          of(entityId),
          this._searchClient.getDataItem('Form', `EntityId eq ${entityId}`, ['Label']).pipe(
            map((forms: any[]) =>
              forms.map((f) => {
                return { id: f.Id, name: f.Label } as FormOption;
              }),
            ),
          ),
        ]),
      ),
      switchMap(([entityId, forms]) => {
        return of(new LoadAvailableFormsSuccess({ entityId, forms }));
      }),
    ),
  );

  changeSelectedForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ChangeSelectedForm>(NavigationActionTypes.ChangeSelectedForm),
      withLatestFrom(this._store.pipe(select(getTopNavigationStackItem))),
      switchMap(([action, stackItem]) => {
        let routeData = {
          entityId: stackItem.navigationTarget.entityId,
          formId: action.payload.formId,
        };
        if (stackItem.navigationTarget.recordId) {
          routeData['recordId'] = stackItem.navigationTarget.recordId;
        }
        return of(new NavigateByRouteData({ routeData, popCount: 1 }));
      }),
    ),
  );

  replaceUrlToToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ReplaceUrlToToken>(NavigationActionTypes.ReplaceUrlToToken),
      map((action) => action.payload.routerState),
      switchMap((routerState) => [
        new NavigateByRouteData({
          routeData: routerState.params,
          queryParams: routerState.queryParams,
        }),
      ]),
    ),
  );

  navigateByRouteData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateByRouteData>(NavigationActionTypes.NavigateByRouteData),
      map((action) => action.payload),
      mergeMap((payload) => forkJoin([of(payload), this.createNavigationTarget(payload.routeData)])),
      filter(([_, navigationTarget]) => navigationTarget != null && navigationTarget != undefined),
      mergeMap(([{ preserveState = undefined, popCount = -1, queryParams, onSuccess }, navigationTarget]) => {
        const actions: Action[] = [];
        const updateStackAction = this.getUpdateStackAction(popCount, preserveState);
        if (updateStackAction) {
          actions.push(updateStackAction);
        }
        actions.push(
          new NavigateByNavigationTargets({
            navigationTargets: [navigationTarget],
            queryParams: queryParams,
            onSuccess,
          }),
        );
        return actions;
      }),
    ),
  );

  navigateByNavigationPath$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateByNavigationPath>(NavigationActionTypes.NavigateByNavigationPath),
      map((action) => action.payload),
      mergeMap((payload) => forkJoin([of(payload), this.getNavigationTargets(payload.navigationPath)])),
      withLatestFrom(this._store.select(shouldConfirmNavigation)),
      mergeMap(
        ([[{ preserveState = undefined, popCount = -1, queryParams, isCustomizationMode }, navigationTargets], shouldConfirmNavigation]) => {
          const actions: Action[] = [];
          const updateStackAction = this.getUpdateStackAction(popCount, preserveState);
          if (updateStackAction) {
            actions.push(updateStackAction);
          }

          actions.push(new NavigateByNavigationTargets({ navigationTargets, queryParams, isCustomizationMode: isCustomizationMode }));

          return this.confirmIfRequired(shouldConfirmNavigation, actions);
        },
      ),
    ),
  );

  navigateByNavigationTargets$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateByNavigationTargets>(NavigationActionTypes.NavigateByNavigationTargets),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getCurrentNavigationPath), this._store.select(shouldConfirmNavigation)),
      mergeMap(([{ navigationTargets, queryParams, onSuccess, isCustomizationMode }, navigationPath, shouldConfirmNavigation]) => {
        const actions: Action[] = [];
        actions.push(
          new LoadNavigationTargetsSuccess({
            navigationTargets: navigationTargets,
          }),
        );
        let path = navigationPath;
        for (let i = 0; i < navigationTargets.length; i++) {
          const target = navigationTargets[i];
          const isTopTarget = i === navigationTargets.length - 1;
          const routerState = this.getRouterState(target, isTopTarget ? queryParams : undefined);
          path += !path ? target.navigationToken : `_${target.navigationToken}`;
          actions.push(
            new PushNavigationStackItem({
              item: {
                navigationToken: target.navigationToken,
                navigationPath: path,
                navigationTarget: target,
                routerState: routerState,
                isCustomizationMode: isCustomizationMode
              },
            }),
          );

          if (isTopTarget) {
            if (isCustomizationMode) {
              actions.push(new CustomizationsActions.LoadRootCustomizationPanelElement());
            } else {
              actions.push(new RouterActions.SetRouteParams({ params: routerState.params }));
              if (queryParams) {
                actions.push(new RouterActions.AddQueryParams({ queryParams }));
              }
              actions.push(
                this.getNavigationAction(target, NavigationMode.SkipLocationChange, path, queryParams, onSuccess),
              );
              actions.push(new LoadAvailableForms({ entityId: target.entityId }));
            }
          }
        }

        return this.confirmIfRequired(shouldConfirmNavigation, actions);
      }),
    ),
  );

  navigateBack$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateBack>(NavigationActionTypes.NavigateBack),
      map((action) => action.payload),
      mergeMap(({ queryParams, isConfirmDisabled }) =>
        of(new NavigateToPrevNavigationStackItem({ popCount: 1, queryParams, isConfirmDisabled })),
      ),
    ),
  );

  navigateToPrevNavigationTarget$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateToPrevNavigationStackItem>(NavigationActionTypes.NavigateToPrevNavigationStackItem),
      map((action) => action.payload),
      withLatestFrom(this._store.select(shouldConfirmNavigation)),
      mergeMap(([payload, shouldConfirmNavigation]) => {
        const actions: Action[] = [];
        actions.push(new PopNavigationStackItems({ popCount: payload.popCount }));
        if (payload.queryParams) {
          actions.push(
            new AddQueryParamsToTopNavigationStackItem({
              queryParams: payload.queryParams,
            }),
          );
        }

        actions.push(new NavigateToTopNavigationStackItem());

        const shouldConfirm = payload.isConfirmDisabled ? false : shouldConfirmNavigation;
        return this.confirmIfRequired(shouldConfirm, actions);
      }),
    ),
  );

  navigateToTopNavigationStackItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateToTopNavigationStackItem>(NavigationActionTypes.NavigateToTopNavigationStackItem),
      withLatestFrom(this._store.pipe(select(getTopNavigationStackItem))),
      mergeMap(([_, item]) => {
        const actions: Action[] = [];
        if (item) {
          actions.push(
            this.getNavigationAction(
              item.navigationTarget,
              NavigationMode.SkipLocationChange,
              item.navigationPath,
              item.routerState.queryParams,
            ),
          );
          if (item.routerState.queryParams) {
            actions.push(new RouterActions.AddQueryParams({ queryParams: item.routerState.queryParams }));
          }
        }
        else {
          actions.push(new NavigateToRecentOrDefaultTarget());
        }
        return actions;
      }),
    ),
  );

  navigateToHomePage$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateToHomePage>(NavigationActionTypes.NavigateToHomePage),
      withLatestFrom(this._store.select(getMenuConfiguration), this._store.select(getSelectedMenuArea), this._store.pipe(select(getIsDirty))),
      mergeMap(([_, menuConfiguration, menuArea, isDirty]) => {
        const homeNavigationTarget = menuArea.homeNavigationToken ?? menuConfiguration.homeNavigationToken;
        return this.confirmIfRequired(isDirty, [new ClearNavigationStack(), homeNavigationTarget
          ? new NavigateByNavigationPath({ navigationPath: homeNavigationTarget })
          : new RouterActions.NavigateToHome()])
      },
      ),
    ),
  );

  navigateToRecentOrDefaultTargetIfNoNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType<NavigateToRecentOrDefaultTarget>(
        NavigationActionTypes.NavigateToRecentOrDefaultTarget,
      ),
      withLatestFrom(this._store.select(getRecentMenuItem), this._store.select(getDefaultMenuItem)),
      mergeMap(([_, recentMenuItem, defaultMenuItem]) => {
        if (recentMenuItem) {
          return of(new SelectMenuItem({ menuItem: recentMenuItem }));
        }

        if (defaultMenuItem) {
          return of(new SelectMenuItem({ menuItem: defaultMenuItem }));
        }

        return of(new NavigateToHomePage())
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _navigationClient: NavigationClient,
    private _searchClient: SearchClient,
    private _dialog: MatDialog,
    private _translationService: EngineTranslationService,
  ) { }

  private confirmIfRequired(shouldConfirm: boolean, actions: Action[]) {
    if (shouldConfirm) {
      const warningMessage = this._translationService.translateInstantly('Navigation.ReturnConfirmation');
      return this._dialog
        .open(ConfirmDialogComponent, {
          data: {
            message: warningMessage,
          },
        })
        .afterClosed()
        .pipe(
          mergeMap((result) => {
            const resetIsDirtyAction = new NavigationActions.SetIsDirty({ isDirty: false });
            return result ? [resetIsDirtyAction, ...actions] : EMPTY;
          }),
        );
    } else {
      return actions;
    }
  }

  private createNavigationTarget(routeData): Observable<NavigationTargetDto> {
    return this._navigationClient.createNavigationTarget(CreateNavigationTargetCommand.fromJS({ routeData })).pipe(
      catchError((error) => {
        this._store.dispatch(new ErrorsActions.HandleError({ error }));
        return EMPTY;
      }),
    );
  }

  private getNavigationTargets(path: string): Observable<NavigationTargetDto[]> {
    const navigationTokens = path.split('_').map((x) => x.toLowerCase());
    if (navigationTokens == null || navigationTokens.length == 0) return of([]);
    return this._store.select(getNavigationTargets).pipe(
      first(),
      switchMap((loadedTargets) => {
        const missingTargetsTokens = navigationTokens.filter((token) => !loadedTargets[token]);
        if (missingTargetsTokens.length === 0) {
          return of(navigationTokens.map((token) => loadedTargets[token]));
        }
        return this._navigationClient.getNavigationTargets(missingTargetsTokens).pipe(
          map((response) => {
            let missingTargets = {};
            if (response?.navigationTargets) {
              missingTargets = response.navigationTargets;
            }
            return navigationTokens.map((t) => (loadedTargets[t] ? loadedTargets[t] : missingTargets[t]));
          }),
          map((navigationTargets) => (navigationTargets.some((t) => !t) ? [] : navigationTargets)),
        );
      }),
    );
  }

  private getRouterState(navigationTarget: NavigationTargetDto, queryParams: Params): IRouterStateUrl {
    const params: Params = {};
    const paramKeys = ['entityId', 'formId', 'viewId', 'recordId'];
    for (const param of paramKeys) {
      if (navigationTarget[param]) {
        params[param] = navigationTarget[param];
      }
    }

    return {
      url: undefined,
      params: params,
      queryParams: queryParams,
    };
  }

  private getNavigationAction(
    navigationTarget: NavigationTargetDto,
    mode?: NavigationMode,
    navigationPath?: string,
    queryParams?: Params,
    onSuccess?: () => void,
  ): Action {
    if (navigationTarget.recordId) {
      return new RouterActions.NavigateToForm(
        {
          recordInfo: {
            entityId: navigationTarget.entityId,
            recordId: navigationTarget.recordId,
            entityName: null,
          },
          formId: navigationTarget.formId,
        },
        {
          mode: mode ? mode : NavigationMode.SkipLocationChange,
          baseNavigationPath: navigationPath,
        },
        queryParams,
        onSuccess,
      );
    }

    if (navigationTarget.formId) {
      return new RouterActions.NavigateToEntityCreateForm(
        {
          entityId: navigationTarget.entityId,
          formId: navigationTarget.formId,
        },
        {
          mode: mode ? mode : NavigationMode.SkipLocationChange,
          baseNavigationPath: navigationPath,
        },
        queryParams,
      );
    }

    if (navigationTarget.viewId) {
      return new RouterActions.NavigateToEntityView(
        {
          entityId: navigationTarget.entityId,
          viewId: navigationTarget.viewId,
        },
        {
          mode: mode ? mode : NavigationMode.SkipLocationChange,
          baseNavigationPath: navigationPath,
        },
        queryParams,
      );
    }

    if (navigationTarget.kanbanId) {
      return new RouterActions.NavigateToEntityKanban(
        {
          entityId: navigationTarget.entityId,
          kanbanId: navigationTarget.kanbanId,
        },
        {
          mode: mode ? mode : NavigationMode.SkipLocationChange,
          baseNavigationPath: navigationPath,
        },
        queryParams,
      );
    }

    console.error('Invalid navigation target');
  }

  private getUpdateStackAction(popCount: number, state: any) {
    if (popCount === -1) {
      return new ClearNavigationStack();
    } else if (popCount > 0) {
      return new PopNavigationStackItems({ popCount });
    } else if (popCount === 0 && state !== undefined) {
      return new SaveNavigationState({ state: state });
    }
  }
}
