import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CustomizationPreviewResult } from "../../../../services/api-clients";
import { ENGINE_VIEW_STATE_SERVICE, EngineViewStateService } from "../../../../engine-views/components/engine-view/services/engine-view-state.service";
import { GridSettings } from "../../../../engine-views/components/engine-view/services/engine-view-settings.service";

@Component({
  selector: 'customization-preview',
  templateUrl: './customization-preview.component.html',
  styleUrls: ['./customization-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ENGINE_VIEW_STATE_SERVICE,
      useClass: EngineViewStateService,
    },
  ],
})
export class CustomizationPreviewComponent {
  defaultGridSettings: GridSettings = {
    areFiltersEnabled: false,
    isGlobalSearchEnabled: false,
    columnsSettings: {}
  }

  @Input() customizationPreview: CustomizationPreviewResult;

}
