import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { NavigateByNavigationPath } from '../store/actions';

@Injectable()
export class NavigateByNavigationPathGuard implements CanActivate {
  constructor(private _store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.params['navigationPath']) {
      this._store.dispatch(
        new NavigateByNavigationPath({
          navigationPath: route.params['navigationPath'],
          queryParams: route.queryParams,
          isCustomizationMode: route.routeConfig.path == ':navigationPath/customizations',
        }),
      );
    }

    return of(true);
  }
}
