import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { CustomizationElement } from "../../../../services/api-clients";
import { CustomizationElementWrapper } from "../../../models/customization-element-wrapper.model";
import { CustomizationElementFlags } from "../../../store/reducers";
import { EngineTranslationService } from "../../../../engine-translations/services/translation.service";
import { AddCustomizationElementEventArgs } from "../../../store/actions";



export interface Button {
  label: string;
  icon: string;
  action: () => void;
}

@Component({
  selector: 'customization-toolbar',
  templateUrl: './customization-toolbar.component.html',
  styleUrls: ['./customization-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizationToolbarComponent {
  private _translationService: EngineTranslationService = inject(EngineTranslationService);

  private _customizationElement: CustomizationElementWrapper;

  get customizationElement(): CustomizationElementWrapper {
    return this._customizationElement;
  };

  @Input() set customizationElement(customizationElement: CustomizationElementWrapper) {
    this._customizationElement = customizationElement;
    this.buttons = customizationElement == null ? [] : this.getButtons(customizationElement);
  }

  @Input()
  rootElement: CustomizationElementWrapper;

  @Output() publish = new EventEmitter<any>();
  @Output() add = new EventEmitter<AddCustomizationElementEventArgs>();
  @Output() remove = new EventEmitter<CustomizationElement>();

  buttons: Button[] = [];

  private getButtons(customizationElement: CustomizationElementWrapper): Button[] {
    let toolbarButtons: Button[] = []

    if (customizationElement.element[CustomizationElementFlags.isRemoved])
      return toolbarButtons;

    if (!customizationElement.schema.isCollection && customizationElement != this.rootElement) {
      toolbarButtons.push({
        label: 'Customizations.Remove',
        icon: 'remove',
        action: () => {
          this.remove.emit(customizationElement.element);
        }
      });
    }

    for (const childSchema of customizationElement.childSchemas) {
      if (!this.customizationElement.schema.isCollection
        && !childSchema.isCollection
        && this.customizationElement.item[childSchema.schemaId.split('.')[1] + 'Id'] != null)
        continue;

      toolbarButtons.push({
        label: this._translationService.translateInstantly('Customizations.Add') + ' ' + childSchema.label,
        icon: childSchema.icon,
        action: () => {
          this.add.emit({ parentElement: customizationElement.element, childSchema });
        }
      });
    }

    return toolbarButtons;
  }
}
