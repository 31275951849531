import { CustomizationElement, CustomizationElementSchema } from "../../services/api-clients";
import { GuidService } from "../../services/guid.service";
import { CustomizationsActionTypes, CustomizationsActions } from "./actions";
import { ICustomizationsState, initialCustomizationsState } from "./state";

export enum CustomizationElementFlags {
  isAdded = '$isAdded',
  isLoaded = '$isLoaded',
  isRemoved = '$isRemoved'
}
export function customizationsReducer(state = initialCustomizationsState, action: CustomizationsActions): ICustomizationsState {
  switch (action.type) {
    case CustomizationsActionTypes.LoadRootCustomizationPanelElementSuccess: {
      let copy = copyCustomizationElement(action.payload.customizationPanel.element);
      copy[CustomizationElementFlags.isLoaded] = true;

      return {
        ...state,
        rootElement: copy,
        forms: {
          ...action.payload.customizationPanel.forms,
          ...state.forms
        },
        schema: {
          ...action.payload.customizationPanel.schema,
          ...state.schema
        }
      };
    }

    case CustomizationsActionTypes.LoadNestedCustomizationPanelElementSuccess: {
      return {
        ...state,
        rootElement: markAsLoaded(state.rootElement, action.payload.parentElement, action.payload.customizationPanel.element),
        forms: {
          ...action.payload.customizationPanel.forms,
          ...state.forms
        },
        schema: {
          ...action.payload.customizationPanel.schema,
          ...state.schema
        }
      };
    }

    case CustomizationsActionTypes.LoadCustomizationPreviewSuccess: {
      return {
        ...state,
        preview: action.payload.customizationPreviewResult
      };
    }

    case CustomizationsActionTypes.SelectCustomizationElement: {
      return {
        ...state,
        selectedElement: action.payload.customizationElement
      }
    }

    case CustomizationsActionTypes.AddCustomizationElement: {
      const childCustomizationElement = new CustomizationElement();
      childCustomizationElement.item = { ...action.payload.childSchema.defaultValues }
      childCustomizationElement.item.Id = GuidService.generateNew();
      childCustomizationElement.schemaId = action.payload.childSchema.schemaId;
      childCustomizationElement.elementId = `${action.payload.childSchema.entityName}_${childCustomizationElement.item.Id}`;
      childCustomizationElement.childElements = [];

      if (action.payload.childSchema.parentIdPropertyName) {
        childCustomizationElement.item[action.payload.childSchema.parentIdPropertyName] = action.payload.parentElement.item.Id;
      }

      let parentDataItem = {
        ...action.payload.parentElement.item,
        ...state.dataItems[action.payload.parentElement.elementId]
      };

      const parentSchema = state.schema[action.payload.parentElement.schemaId];
      const childSchema = action.payload.childSchema;
      if (!parentSchema.isCollection && !childSchema.isCollection) {
        const schemaPart = childSchema.schemaId.split('.')[1];
        if (schemaPart) {
          // TODO: to remove?
          parentDataItem[schemaPart + 'Id'] = childCustomizationElement.item.Id;
        }
      }

      return {
        ...state,
        rootElement: addCustomizationElement(state.rootElement, action.payload.parentElement, childCustomizationElement, action.payload.childSchema),
        dataItems: {
          ...state.dataItems,
          [childCustomizationElement.elementId]: childCustomizationElement.item,
          [action.payload.parentElement.elementId]: parentDataItem 
        }
      }
    }

    case CustomizationsActionTypes.MarkAsRemoved: {
      return {
        ...state,
        rootElement: markAsRemoved(state.rootElement, action.payload.customizationElement)
      }
    }


    case CustomizationsActionTypes.SetCustomizationElementItem: {
      return {
        ...state,
        dataItems: {
          ...state.dataItems,
          [action.payload.elementId]: action.payload.item
        }
      }
    }

    default:
      return state;
  }
}

function markAsLoaded(rootElement: CustomizationElement, oldElement: CustomizationElement, newElement: CustomizationElement) {
  const markAsLoadedInternal = (element: CustomizationElement): CustomizationElement => {
    if (element.elementId === oldElement.elementId) {
      let copy = copyCustomizationElement(element);
      copy[CustomizationElementFlags.isLoaded] = true;
      copy.childElements = newElement.childElements;

      return copy;
    }

    if (!!element.childElements && element.childElements.length > 0) {
      let copy = copyCustomizationElement(element);
      copy.childElements = element.childElements.map(markAsLoadedInternal);

      return copy;
    }

    return element;
  };

  return markAsLoadedInternal(rootElement);
}

function addCustomizationElement(rootElement: CustomizationElement, parentElement: CustomizationElement, childElement: CustomizationElement, childElementSchema: CustomizationElementSchema) {
  const addCustomizationElementInternal = (element: CustomizationElement): CustomizationElement => {
    if (element.elementId === parentElement.elementId) {
      let childCopy = copyCustomizationElement(childElement);
      childCopy[CustomizationElementFlags.isAdded] = true;

      let parentCopy = copyCustomizationElement(element);
      parentCopy.childElements.push(childCopy);

      return parentCopy;
    }

    if (!!element.childElements && element.childElements.length > 0) {
      let copy = copyCustomizationElement(element);
      copy.childElements = element.childElements.map(addCustomizationElementInternal);

      return copy;
    }

    return element;
  };

  return addCustomizationElementInternal(rootElement);
}

function markAsRemoved(rootElement: CustomizationElement, toRemove: CustomizationElement) {
  const markAsRemovedInternal = (element: CustomizationElement): CustomizationElement => {
    if (element.elementId === toRemove.elementId) {
      if (element[CustomizationElementFlags.isAdded])
        return null;

      let copy = copyCustomizationElement(element);
      copy[CustomizationElementFlags.isRemoved] = true;

      return copy;
    }

    if (!!element.childElements && element.childElements.length > 0) {
      let copy = copyCustomizationElement(element);
      copy.childElements = element.childElements.map(markAsRemovedInternal).filter(x => !!x);

      return copy;
    }

    return element;
  };

  return markAsRemovedInternal(rootElement);
}

function copyCustomizationElement(element: CustomizationElement): CustomizationElement {
  const childElements = element?.childElements != null && element.childElements.length > 0
    ? element.childElements.map(copyCustomizationElement)
    : [];

  const { childElements: _, ...source } = element;
  let copy = CustomizationElement.fromJS(source);
  copy[CustomizationElementFlags.isAdded] = element[CustomizationElementFlags.isAdded];
  copy[CustomizationElementFlags.isLoaded] = element[CustomizationElementFlags.isLoaded];
  copy[CustomizationElementFlags.isRemoved] = element[CustomizationElementFlags.isRemoved];
  copy.childElements = childElements;


  return copy;
}
