import { Action } from '@ngrx/store';
import { CustomizationElement, CustomizationElementSchema, CustomizationPanel, CustomizationPreviewResult } from '../../services/api-clients';
import { CustomizationElementWrapper } from '../models/customization-element-wrapper.model';

export enum CustomizationsActionTypes {
  LoadRootCustomizationPanelElement = '[Customizations] Load Root Customization Panel Element',
  LoadRootCustomizationPanelElementSuccess = '[Customizations] Load Root Customization Panel Element Success',
  LoadNestedCustomizationPanelElement = '[Customizations] Load Nested Customization Panel Element',
  LoadNestedCustomizationPanelElementSuccess = '[Customizations] Load Nested Customization Panel Element Success',
  PublishCustomizations = '[Customizations] Publish Customizations',
  PublishCustomizationsSuccess = '[Customizations] Publish Customizations Success',
  LoadCustomizationPreview = '[Customizations] Load Customization Preview',
  LoadCustomizationPreviewSuccess = '[Customizations] Load Customization Preview Success',
  SelectCustomizationElement = '[Customizations] Select Customization Element',
  AddCustomizationElement = '[Customizations] Add Customization Element',
  MarkAsRemoved = '[Customizations] Mark As Removed',
  SetCustomizationElementItem = '[Customizations] Set Customization Element Item',
}
export class LoadRootCustomizationPanelElement implements Action {
  public readonly type = CustomizationsActionTypes.LoadRootCustomizationPanelElement;
}

export class LoadRootCustomizationPanelElementSuccess implements Action {
  public readonly type = CustomizationsActionTypes.LoadRootCustomizationPanelElementSuccess;

  public constructor(public payload: { customizationPanel: CustomizationPanel }) { }
}

export class LoadNestedCustomizationPanelElement implements Action {
  public readonly type = CustomizationsActionTypes.LoadNestedCustomizationPanelElement;

  public constructor(public payload: { customizationElement: CustomizationElement }) { }
}

export class LoadNestedCustomizationPanelElementSuccess implements Action {
  public readonly type = CustomizationsActionTypes.LoadNestedCustomizationPanelElementSuccess;

  public constructor(public payload: { parentElement: CustomizationElement, customizationPanel: CustomizationPanel }) { }
}

export class PublishCustomizations implements Action {
  public readonly type = CustomizationsActionTypes.PublishCustomizations;

  public constructor(public payload: { rootElement: CustomizationElementWrapper, onSuccess: () => void }) { }
}

export class PublishCustomizationsSuccess implements Action {
  public readonly type = CustomizationsActionTypes.PublishCustomizationsSuccess;
}

export class LoadCustomizationPreview implements Action {
  public readonly type = CustomizationsActionTypes.LoadCustomizationPreview;

  public constructor(public payload: { entityName: string, recordId: string }) { }
}

export class LoadCustomizationPreviewSuccess implements Action {
  public readonly type = CustomizationsActionTypes.LoadCustomizationPreviewSuccess;

  public constructor(public payload: { customizationPreviewResult: CustomizationPreviewResult }) { }
}

export class SelectCustomizationElement implements Action {
  public readonly type = CustomizationsActionTypes.SelectCustomizationElement;

  public constructor(public payload: { customizationElement: CustomizationElement }) { }
}

export interface AddCustomizationElementEventArgs {
  parentElement: CustomizationElement;
  childSchema: CustomizationElementSchema;
}

export class AddCustomizationElement implements Action {
  public readonly type = CustomizationsActionTypes.AddCustomizationElement;

  public constructor(public payload: AddCustomizationElementEventArgs) { }
}

export class MarkAsRemoved implements Action {
  public readonly type = CustomizationsActionTypes.MarkAsRemoved;

  public constructor(public payload: { customizationElement: CustomizationElement }) { }
}

export class SetCustomizationElementItem implements Action {
  public readonly type = CustomizationsActionTypes.SetCustomizationElementItem;

  public constructor(public payload: { elementId: string, item: any }) { }
}

export type CustomizationsActions =
  | LoadRootCustomizationPanelElement
  | LoadRootCustomizationPanelElementSuccess
  | LoadNestedCustomizationPanelElement
  | LoadNestedCustomizationPanelElementSuccess
  | PublishCustomizations
  | PublishCustomizationsSuccess
  | LoadCustomizationPreview
  | LoadCustomizationPreviewSuccess
  | SelectCustomizationElement
  | AddCustomizationElement
  | MarkAsRemoved
  | SetCustomizationElementItem;
