import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CustomizationElement } from "../../../services/api-clients";
import { Store, select } from "@ngrx/store";
import { LoadNestedCustomizationPanelElement, LoadCustomizationPreview, MarkAsRemoved, PublishCustomizations, SelectCustomizationElement, AddCustomizationElement, SetCustomizationElementItem, AddCustomizationElementEventArgs } from "../../store/actions";
import { getCustomizationPreview, getWrappedSelectedElement } from "../../store/selectors";
import { CustomizationElementsTreeComponent } from "./customization-elements-tree/customization-elements-tree.component";
import { Subject, debounceTime, takeUntil, tap } from "rxjs";
import { CustomizationElementFlags } from "../../store/reducers";
import { CustomizationElementWrapper } from "../../models/customization-element-wrapper.model";

@Component({
  selector: 'app-engine-customization-panel',
  templateUrl: './engine-customization-panel.component.html',
  styleUrls: ['./engine-customization-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngineCustomizationPanelComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _debouncer$: Subject<any> = new Subject<any>();
  private _rootElement: CustomizationElementWrapper;

  @ViewChild(CustomizationElementsTreeComponent)
  customizationElementsTreeComponent: CustomizationElementsTreeComponent;

  @Input()
  set rootElement(root: CustomizationElementWrapper) {
    if (this._rootElement == null && root != null) {
      this._store.dispatch(new SelectCustomizationElement({ customizationElement: root.element }));
    }

    this._rootElement = root;
  }

  get rootElement() {
    return this._rootElement;
  }

  selectedElement$ = this._store.pipe(select(getWrappedSelectedElement));
  customizationPreview$ = this._store.pipe(select(getCustomizationPreview));
  constructor(private _store: Store) {
  }

  ngOnInit() {
    this._debouncer$.pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      tap(_ => {
        this.reloadCustomizationPreview();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  onSelectedElementChanged(customizationElement: CustomizationElementWrapper) {
    this._store.dispatch(new SelectCustomizationElement({ customizationElement: null }));

    if (customizationElement != null) {
      setTimeout(() => {
        if (!customizationElement.schema.isCollection && !customizationElement.element[CustomizationElementFlags.isLoaded]) {
          this._store.dispatch(new LoadNestedCustomizationPanelElement({ customizationElement: customizationElement.element }));
        }

        this._store.dispatch(new SelectCustomizationElement({ customizationElement: customizationElement.element }));
      }, 0);
    }
  }

  onElementLoading(element: CustomizationElementWrapper) {
    this._store.dispatch(new LoadNestedCustomizationPanelElement({ customizationElement: element.element }));
  }

  onPublishClicked() {
    this._store.dispatch(new PublishCustomizations({
      rootElement: this.rootElement,
      onSuccess: () => {
        this.customizationElementsTreeComponent.clearState();
      }
    }));
  }

  onAddClicked(args: AddCustomizationElementEventArgs) {
    this._store.dispatch(new AddCustomizationElement(args));
    this._debouncer$.next(Date());
  }

  onRemoveClicked(customizationElement: CustomizationElement) {
    this._store.dispatch(new MarkAsRemoved({ customizationElement }));
    this._debouncer$.next(Date());
  }

  onDataItemChanged(customizationElement: CustomizationElement, dataItem: any) {
    this._store.dispatch(new SetCustomizationElementItem({ elementId: customizationElement.elementId, item: dataItem }));
    this._debouncer$.next(Date());
  }

  private reloadCustomizationPreview() {
    if (this.rootElement) {
      this._store.dispatch(new LoadCustomizationPreview({
        entityName: this.rootElement.schema.entityName,
        recordId: this.rootElement.element.item.Id,
      }));
    }
  }
}
