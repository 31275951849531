import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, Subject, of, BehaviorSubject, combineLatest } from 'rxjs';
import { filter, switchMap, first, map, takeUntil, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  ExecutionContext,
  ButtonExecutionContext,
  MenuItemExecutionContext,
  FormExecutionContext,
  ControlExecutionContext,
} from '@core/execution-context';
import {
  FormContextType,
  IButtonExecutionContext,
  IContextMenuContext,
  IControlExecutionContext,
  IDialogContext,
  IExecutionContext,
  IExecutionEvent,
  IFormContext,
  IFormExecutionContext,
  IMenuItemExecutionContext,
  IViewContext,
} from 'src/engine-sdk/contract';
import { WidgetDirective } from '../widgets/directives/widget.directive';
import { IKanbanContext } from 'src/engine-sdk/contract/kanban';
import { EngineButtonFormControlDirective } from '@core/engine-forms/directives/engine-button-form-control.directive';
import { EngineFormControlDirective } from '@core/engine-forms/directives/engine-form-control.directive';
import { ErrorHandlerProvider } from '@core/errors/services/error-handler-provider.service';
import { NavigationService } from '@core/navigation/services/navigation.service';
import { ContextMenuButtonComponent } from '@core/context-menu/context-menu/context-menu-button/context-menu-button.component';
import { IEngineDataContext, IEngineFormControlDataContext, IEngineFormDataContext, IEngineViewDataContext } from '../../engine-sdk/contract/engine-data-context';

export class CancellationToken {
  private _tokenState: boolean = false;

  public isCancellationRequested(): boolean {
    return this._tokenState;
  }

  public cancel() {
    this._tokenState = true;
  }
}

export interface ICancellationTokenProvider {
  getCancellationToken(): CancellationToken;
}

export type ApplicationContextType = 'view' | 'form' | undefined;

export interface WidgetDictionary<TWidget> {
  [widgetId: string]: TWidget;
}

@Injectable({
  providedIn: 'root',
})
export class ContextService implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  private _views: WidgetDictionary<IViewContext> = {};
  private _dialogs: WidgetDictionary<IDialogContext> = {};
  private _mainForm$: BehaviorSubject<IFormContext> = new BehaviorSubject(null);
  private _mainView$: BehaviorSubject<IViewContext> = new BehaviorSubject(null);
  private _mainKanban$: BehaviorSubject<IKanbanContext> = new BehaviorSubject(null);
  private _dialogForms$: BehaviorSubject<{ [dialogId: string]: IFormContext }> = new BehaviorSubject({});
  private _forms$: BehaviorSubject<{ [formId: string]: IFormContext }> = new BehaviorSubject({});
  private _contextMenus$: BehaviorSubject<WidgetDictionary<IContextMenuContext>> = new BehaviorSubject({});

  private _currentCancellationToken: CancellationToken = new CancellationToken();

  constructor(
    private _navigationService: NavigationService,
    private _handlerProvider: ErrorHandlerProvider,
    private _injector: Injector,
  ) {
    this.startListenOnContextChange();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  createContextForError(): Observable<IExecutionContext> {
    return of(new ExecutionContext(this._injector, new CancellationToken()));
  }

  createMenuContextButtonExecutionContext(
    button: ContextMenuButtonComponent,
    buttonEvents$: Observable<IExecutionEvent>,
    buttonDataContext: IEngineDataContext,
    executionEvent?: IExecutionEvent,
  ): Observable<IButtonExecutionContext> {
    return combineLatest([button.rootWidget.isWidgetLoaded$, this.getContextMenuAsync(buttonDataContext)]).pipe(
      filter(([isWidgetLoaded, contextMenu]) => isWidgetLoaded && contextMenu != null),
      first(),
      switchMap((_) =>
        of(
          new ButtonExecutionContext(
            this._injector,
            button,
            buttonEvents$,
            this._currentCancellationToken,
            buttonDataContext,
            executionEvent,
          ),
        ),
      ),
    );
  }

  createFormButtonExecutionContext(
    button: EngineButtonFormControlDirective,
    buttonEvents$: Observable<IExecutionEvent>,
    buttonDataContext: IEngineDataContext,
    executionEvent?: IExecutionEvent,
  ): Observable<IButtonExecutionContext> {
    return combineLatest([button.rootWidget.isWidgetLoaded$, this.getContextMenuAsync(buttonDataContext)]).pipe(
      filter(([isWidgetLoaded, contextMenu]) => isWidgetLoaded && contextMenu != null),
      first(),
      switchMap((_) =>
        of(
          new ButtonExecutionContext(
            this._injector,
            button,
            buttonEvents$,
            this._currentCancellationToken,
            buttonDataContext,
            executionEvent,
          ),
        ),
      ),
    );
  }

  createMenuItemExecutionContext(
    events$: Observable<IExecutionEvent>,
    executionEvent?: IExecutionEvent,
  ): Observable<IMenuItemExecutionContext> {
    return of(new MenuItemExecutionContext(this._injector, events$, new CancellationToken(), executionEvent));
  }

  createFormExecutionContext(
    form: WidgetDirective,
    events$: Observable<IExecutionEvent>,
    dataContext: IEngineFormDataContext,
    executionEvent?: IExecutionEvent,
  ): Observable<IFormExecutionContext> {
    return combineLatest([form.rootWidget.isWidgetLoaded$, this.waitForContextMenuAsync(dataContext)]).pipe(
      filter(([isWidgetLoaded, contextMenuSignal]) => isWidgetLoaded && contextMenuSignal),
      first(),
      switchMap(() =>
        of(
          new FormExecutionContext(
            this._injector,
            events$,
            this._currentCancellationToken,
            executionEvent,
            dataContext.dialogId,
            dataContext
          ),
        ),
      ),
    );
  }

  createControlExecutionContext(
    control: EngineFormControlDirective,
    events$: Observable<IExecutionEvent>,
    controlDataContext: IEngineFormControlDataContext,
    executionEvent?: IExecutionEvent
  ): Observable<IControlExecutionContext> {
    return combineLatest([control.rootWidget.isWidgetLoaded$, this.waitForContextMenuAsync(controlDataContext)]).pipe(
      filter(([isWidgetLoaded, contextMenuSignal]) => isWidgetLoaded && contextMenuSignal),
      first(),
      switchMap(() =>
        of(
          new ControlExecutionContext(
            this._injector,
            events$,
            this._currentCancellationToken,
            controlDataContext,
            executionEvent,
          ),
        ),
      ),
    );
  }

  getMainContextMenu(): IContextMenuContext {
    return this._contextMenus$.value['main'];
  }

  getMainFormContext(): IFormContext {
    return this._mainForm$.value;
  }

  getMainFormContextAsync(): Observable<IFormContext> {
    return this._mainForm$.asObservable();
  }

  getDialogFormContext(dialogId: string): IFormContext {
    return this._dialogForms$.value[dialogId];
  }

  getDialogFormContextAsync(dialogId: string): Observable<IFormContext> {
    return this._dialogForms$.asObservable().pipe(map((dialogForms) => dialogForms[dialogId]));
  }

  getFormContext(formId: string): IFormContext {
    return this._forms$.value[formId];
  }

  getFormContextAsync(formId: string): Observable<IFormContext> {
    return this._forms$.asObservable().pipe(map((forms) => forms[formId]));
  }

  getFormContexts() {
    return this._forms$.value;
  }

  getDialogContext(dialogId: string): IDialogContext {
    return this._dialogs[dialogId];
  }

  createViewContext(dataContext?: IEngineViewDataContext) {
    const isSubgridContext = !!dataContext?.controlId;
    if (isSubgridContext) {
      return this._views[dataContext.controlId];
    }
    return this._mainView$.value;
  }

  registerDialog(dialog: IDialogContext) {
    this._dialogs[dialog.id] = dialog;
  }

  unregisterDialog(dialog: IDialogContext) {
    this._dialogs[dialog.id] = undefined;
  }

  registerForm(formContext: IFormContext) {
    this._forms$.next({ ...this._forms$.value, [formContext.id]: formContext });
    if (formContext.getDataContext().type == FormContextType.Main) {
      this.registerMainForm(formContext);
    } else if (formContext.getDataContext().type == FormContextType.Dialog) {
      this.registerDialogForm(formContext);
    }
  }

  getKanbanContext(): IKanbanContext {
    return this._mainKanban$.value;
  }

  unregisterForm(formContext: IFormContext) {
    this._forms$.next({ ...this._forms$.value, [formContext.id]: undefined });
    if (formContext.getDataContext().type == FormContextType.Main) {
      this.unregisterMainForm(formContext);
    } else if (formContext.getDataContext().type == FormContextType.Dialog) {
      this.unregisterDialogForm(formContext);
    }
  }

  registerMainView(viewContext: IViewContext) {
    this._mainView$.next(viewContext);
  }

  unregisterMainView(viewContext: IViewContext) {
    if (this._mainView$.value == viewContext) {
      this._mainView$.next(null);
    }
  }

  registerView(viewContext: IViewContext) {
    const dataContext = viewContext.getDataContext();
    this._views[dataContext.controlId] = viewContext;
  }

  unregisterView(viewContext: IViewContext) {
    const dataContext = viewContext.getDataContext();
    this._views[dataContext.controlId] = undefined;
  }

  registerKanban(kanbanContext: IKanbanContext) {
    this._mainKanban$.next(kanbanContext);
  }

  unregisterKanban() {
    this._mainKanban$.next(null);
  }

  registerContextMenu(contextMenu: IContextMenuContext) {
    const contextMenuId = this.getContextMenuUniqueId(contextMenu.dataContext);
    this._contextMenus$.next({ ...this._contextMenus$.value, [contextMenuId]: contextMenu });
  }

  unregisterContextMenu(contextMenu: IContextMenuContext) {
    const contextMenuId = this.getContextMenuUniqueId(contextMenu.dataContext);

    let menus = { ...this._contextMenus$.value };
    delete menus[contextMenuId];

    this._contextMenus$.next(menus);
  }

  waitForContextMenuAsync(dataContext: IEngineDataContext): Observable<boolean> {
    if (dataContext == null) {
      return of(true);
    }

    if (dataContext.formId && dataContext.type == FormContextType.SubForm) {
      return of(true);
    }

    return this.getContextMenuAsync(dataContext)
      .pipe(
        takeUntil(this._destroy$),
        map(menu => menu != null)
      );
  }

  private registerMainForm(formContext: IFormContext) {
    this._mainForm$.next(formContext);
  }

  private unregisterMainForm(formContext: IFormContext) {
    if (this._mainForm$.value == formContext) {
      this._mainForm$.next(null);
    }
  }

  private registerDialogForm(formContext: IFormContext) {
    const dialogId = formContext.getDataContext().dialogId;
    this._dialogForms$.next({ ...this._dialogForms$.value, [dialogId]: formContext });
  }

  private unregisterDialogForm(formContext: IFormContext) {
    const dialogId = formContext.getDataContext().dialogId;
    this._dialogForms$.next({ ...this._dialogForms$.value, [dialogId]: undefined });
  }

  private startListenOnContextChange(): void {
    this._navigationService
      .getTopNavigationStackItem()
      .pipe(
        takeUntil(this._destroy$),
        filter((stackItem) => !!stackItem),
        distinctUntilChanged((prev, curr) => prev.navigationPath === curr.navigationPath),
        tap((_) => this.onContextChange()),
      )
      .subscribe();
  }

  private onContextChange() {
    this._handlerProvider.clearScopedHandlers();
    this._currentCancellationToken.cancel();
    this._currentCancellationToken = new CancellationToken();
  }

  private getContextMenuUniqueId(dataContext: any) {
    if (dataContext.isSubgridColumn && dataContext.recordId) {
      return `columnMenu_${dataContext.viewId}_${dataContext.recordId}`;
    }

    if (dataContext.isSubgrid && dataContext.controlId) {
      return 'subgridMenu_' + dataContext.controlId;
    }

    if (dataContext.dialogId) {
      return 'dialogMenu' + dataContext.dialogId;
    }


    return 'main';
  }

  private getContextMenuAsync(dataContext: any) {
    const menuId = this.getContextMenuUniqueId(dataContext);
    return this._contextMenus$.asObservable().pipe(map((menus) => menus[menuId]));
  }
}
