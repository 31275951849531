<customization-toolbar [rootElement]="rootElement"
                       [customizationElement]="selectedElement$ | async"
                       (publish)="onPublishClicked()"
                       (add)="onAddClicked($event)"
                       (remove)="onRemoveClicked($event)">
</customization-toolbar>

<app-main-notification-bar></app-main-notification-bar>

<div class="container">
  <div class="column">
    <customization-elements-tree *ngIf="rootElement"
                                 (selectionChange)="onSelectedElementChanged($event)"
                                 (loadElement)="onElementLoading($event)"
                                 [rootElement]="rootElement">
    </customization-elements-tree>
  </div>

  <div class="column">
    <customization-preview [customizationPreview]="customizationPreview$ | async" style="width: 100%"></customization-preview>
  </div>

  <div class="column">
    <ng-container *ngIf="selectedElement$ | async as selectedElement">
      <customization-element-properties *ngIf="selectedElement.element && selectedElement.form"
                                        [form]="selectedElement.form"
                                        [dataItem]="selectedElement.item"
                                        (dataItemChange)="onDataItemChanged(selectedElement.element, $event)">
      </customization-element-properties>

      <div *ngIf="!selectedElement.element || !selectedElement.form" class="properties-placeholder">
        <mat-icon>settings</mat-icon>
      </div>

    </ng-container>
  </div>

</div>
