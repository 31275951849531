import { CustomizationElement, CustomizationElementSchema, CustomizationPreviewResult, FormDto } from "../../services/api-clients";

export interface CustomizationSchema {
  [schemaId: string]: CustomizationElementSchema
}

export interface CustomizationForms {
  [formId: string]: FormDto
}

export interface CustomizationItems {
  [elementId: string]: any
}

export const FEATURE_NAME: string = 'customizations-feature';
export interface ICustomizationsState {
  rootElement: CustomizationElement,
  selectedElement: CustomizationElement,
  preview: CustomizationPreviewResult,
  forms: CustomizationForms,
  schema: CustomizationSchema,
  dataItems: CustomizationItems
}

export const initialCustomizationsState: ICustomizationsState = {
  rootElement: null,
  selectedElement: null,
  preview: null,
  forms: {},
  schema: {},
  dataItems: {},
};
