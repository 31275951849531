import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { tap } from "rxjs";
import { CustomizationElementFlags } from "../../../store/reducers";
import { CustomizationElementWrapper } from "../../../models/customization-element-wrapper.model";

@Component({
  selector: 'customization-elements-tree',
  templateUrl: './customization-elements-tree.component.html',
  styleUrls: ['./customization-elements-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizationElementsTreeComponent implements OnInit {
  treeControl = new NestedTreeControl<CustomizationElementWrapper, string>(node => node.childElements, {
    trackBy: node => node.element.elementId,
  });
  dataSource = new MatTreeNestedDataSource<CustomizationElementWrapper>();
  selection = new SelectionModel<CustomizationElementWrapper>(false, [], true, (e1, e2) => e1.element.elementId == e2.element.elementId);
  loadState = new SelectionModel<CustomizationElementWrapper>(true, [], true, (e1, e2) => e1.element.elementId == e2.element.elementId);

  get rootElement(): CustomizationElementWrapper {
    return this.dataSource.data[0];
  }

  @Input()
  set rootElement(element: CustomizationElementWrapper) {
    const treeRootElement = this.dataSource.data?.[0];
    if (treeRootElement?.element?.elementId != element?.element?.elementId) {
      this.clearState();

      if (element != null) {
        this.treeControl.expand(element);
        this.selection.select(element);
      }
    }

    this.dataSource.data = element == null ? null : [element];
  }

  @Output()
  selectionChange = new EventEmitter<CustomizationElementWrapper>();

  @Output()
  loadElement = new EventEmitter<CustomizationElementWrapper>();

  ngOnInit() {
    this.selection.changed.asObservable().pipe(
      tap(event => {
        this.selectionChange.emit(event.source.selected[0]);
      })
    ).subscribe();
  }

  hasChild = (_: number, node: CustomizationElementWrapper) => !!node.childElements && node.childElements.length > 0;

  canExpand = (_: number, node: CustomizationElementWrapper) => node.schema.isCollection
    ? this.hasChild(_, node)
    : ((!this.loadState.isSelected(node) || this.hasChild(_, node)) && !node.element[CustomizationElementFlags.isRemoved]);

  toggleSelection(node: CustomizationElementWrapper) {
    this.selection.toggle(node);
  }

  onExpandClick(node: CustomizationElementWrapper) {
    if (this.loadState.isSelected(node) || this.hasChild(0, node)) {
      this.loadState.select(node);
      return;
    };

    this.loadElement.emit(node);
    this.loadState.select(node);
  }

  clearState() {
    this.dataSource.data = null;
    this.treeControl.collapseAll();
    this.selection.clear();
    this.loadState.clear();
  }

  getLabel(node: CustomizationElementWrapper) {
    if (node.schema.isCollection) {
      return node.schema.label;
    }

    return node.item.Name ?? node.schema.label;
  }
}
