import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { INavigationContext, INavigationOptions } from 'src/engine-sdk';
import { RouterActions } from '@core/router/store';
import { NavigationStackItem } from '@core/navigation/store/state';
import { NavigationActions, NavigationSelectors } from '@core/navigation/store';
import { RouterService } from '@core/router/services/router.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements INavigationContext {
  constructor(private _store: Store, private routerService: RouterService) {
    this.getTopNavigationStackItem()
      .pipe(
        filter((x) => !!x),
        tap((stackItem) => {
          this.routerService.syncRouting(stackItem.navigationPath, stackItem.routerState.queryParams, stackItem.isCustomizationMode);
        }),
      )
      .subscribe();
  }

  getTopNavigationStackItem(): Observable<NavigationStackItem> {
    return this._store.select(NavigationSelectors.getTopNavigationStackItem);
  }

  //#region INavigationContext
  getNavigationStack(): Observable<NavigationStackItem[]> {
    return this._store.select(NavigationSelectors.getNavigationStack);
  }

  getNavigationPath(): Observable<string> {
    return this._store.select(NavigationSelectors.getCurrentNavigationPath);
  }

  navigateBack(queryParams?: any, isConfirmDisabled?: boolean) {
    this._store.dispatch(new NavigationActions.NavigateBack({ queryParams, isConfirmDisabled }));
  }

  addQueryParams(queryParams: Params) {
    this._store.dispatch(new RouterActions.AddQueryParams({ queryParams }));
  }

  getQueryParams(): Observable<Params> {
    return this._store.select(NavigationSelectors.getQueryParams);
  }

  getQueryParam(key: string): Observable<any> {
    return this.getQueryParams().pipe(
      map((params) => (params ? params[key] : undefined)),
      distinctUntilChanged(),
    );
  }

  saveNavigationState(state: any) {
    this._store.dispatch(new NavigationActions.SaveNavigationState({ state }));
  }

  getNavigationState(): Observable<any> {
    return this._store.select(NavigationSelectors.getCurrentNavigationState);
  }

  navigateToCreateForm(entityId: string, formId: string, options?: INavigationOptions) {
    this.navigateByRouteData({ entityId, formId }, options ?? {});
  }

  navigateToForm(entityId: string, formId: string, recordId: string, options?: INavigationOptions) {
    this.navigateByRouteData({ entityId, formId, recordId }, options ?? {});
  }

  navigateByRouteData(routeData: Params, { preserveState = undefined, popCount = -1, queryParams = undefined }) {
    this._store.dispatch(
      new NavigationActions.NavigateByRouteData({
        routeData,
        popCount,
        preserveState,
        queryParams,
      }),
    );
  }

  navigateByNavigationPath(
    navigationPath: string,
    { preserveState = undefined, popCount = -1, queryParams = undefined },
  ) {
    this._store.dispatch(
      new NavigationActions.NavigateByNavigationPath({
        navigationPath,
        preserveState,
        popCount,
        queryParams,
      }),
    );
  }

  //#endregion
}
