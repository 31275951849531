<mat-toolbar>
  <button mat-button (click)="publish.emit()" class="highlighted-button">
    <mat-icon>publish</mat-icon>
    {{'Customizations.Publish' | translate }}
  </button>

  <button *ngFor="let button of buttons" mat-button (click)="button.action()">
    <mat-icon>{{button.icon}}</mat-icon>
    {{button.label | translate }}
  </button>
</mat-toolbar>
