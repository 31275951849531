import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormDto, } from "../../../../services/api-clients";
import { FormContextType } from "../../../../../engine-sdk";
import { FormDefinition } from "../../../../engine-forms/components/engine-form/engine-form.model";
import { GuidService } from "../../../../services/guid.service";

@Component({
  selector: 'customization-element-properties',
  templateUrl: './customization-element-properties.component.html',
  styleUrls: ['./customization-element-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizationElementPropertiesComponent {
  private _form: FormDto;

  formDefinition: FormDefinition;

  get form(): FormDto {
    return this._form;
  }

  @Input() set form(value: FormDto) {
    this._form = value;
    this.createFormDefinition();
  }

  @Input() dataItem: any;

  @Output() dataItemChange = new EventEmitter<any>();

  onDataItemChanged(dataItem: any) {
    this.dataItemChange.emit(dataItem);
  }

  private createFormDefinition() {
    if (this.form == null) {
      this.formDefinition = null;
    } else {
      this.formDefinition = {
        id: GuidService.generateNew(),
        tabs: this.form.tabs,
        name: this.form.name,
        contextInfo: { type: FormContextType.SubForm },
        recordInfo: {
          entityName: this.form.entityName,
          recordId: null,
          entityId: null
        },
        uiScripts: this.form.uiScripts
      };
    }
  }
}
