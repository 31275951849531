import { NgModule } from "@angular/core";
import { EngineCustomizationPanelComponent } from "./engine-customization-panel.component";
import { CustomizationElementsTreeComponent } from "./customization-elements-tree/customization-elements-tree.component";
import { UiComponentsModule } from "../../../../shared/ui-components/ui-components.module";
import { CustomizationElementPropertiesComponent } from "./customization-element-properties/customization-element-properties.component";
import { SharedModule } from "../../../../shared/shared.module";
import { CustomizationToolbarComponent } from "./customization-toolbar/customization-toolbar.component";
import { EngineFormsModule } from "../../../engine-forms/engine-forms.module";
import { NotificationModule } from "../../../notification/notification.module";
import { CustomizationPreviewComponent } from "./customization-preview/customization-preview.component";
import { EngineViewModule } from "../../../engine-views/components/engine-view/engine-view.module";

@NgModule({
  declarations: [
    CustomizationElementsTreeComponent,
    CustomizationElementPropertiesComponent,
    CustomizationToolbarComponent,
    CustomizationPreviewComponent,
    EngineCustomizationPanelComponent,
  ],
  imports: [
    UiComponentsModule,
    SharedModule,
    EngineFormsModule,
    NotificationModule,
    EngineViewModule,
  ],
  exports: [
    EngineCustomizationPanelComponent
  ]
})
export class EngineCustomizationPanelModule {
}
