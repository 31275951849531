import { ModuleWithProviders, NgModule } from "@angular/core";
import { EngineCustomizationPanelModule } from "./components/customization-panel/engine-customization-panel.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { FEATURE_NAME } from "./store/state";
import { reducers } from "./store";
import { CustomizationsEffects } from "./store/effects";

@NgModule({
  declarations: [
  ],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([CustomizationsEffects]),
    EngineCustomizationPanelModule
  ],
  exports: [
    EngineCustomizationPanelModule
  ]
})
export class EngineCustomizationsModule {
  static forRoot(): ModuleWithProviders<EngineCustomizationsModule> {
    return {
      ngModule: EngineCustomizationsModule,
    };
  }
}
