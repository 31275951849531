import { ActionReducerMap } from '@ngrx/store';
import { customizationsReducer } from './reducers';
import { ICustomizationsState } from './state';
import * as CustomizationsSelectors from './selectors';
import * as CustomizationsActions from './actions';

export { CustomizationsActions, CustomizationsSelectors };

export interface CustomizationsFeatureState {
  customizations: ICustomizationsState;
}

export const reducers: ActionReducerMap<CustomizationsFeatureState> = {
  customizations: customizationsReducer,
};
