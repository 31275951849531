import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { CustomizationsSelectors } from "../../core/engine-customizations/store";

@Component({
  selector: 'app-main-customization-panel.component',
  templateUrl: './main-customization-panel.component.html',
  styleUrls: ['./main-customization-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainCustomizationPanelComponent {

  rootElement$ = this._store.select(CustomizationsSelectors.getWrappedRootElement);

  constructor(private _store: Store) {
  }
}
