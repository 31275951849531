import { AfterViewInit, Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject, Observable, combineLatest, AsyncSubject, of, forkJoin, BehaviorSubject, EMPTY } from 'rxjs';
import { takeUntil, map, filter, tap, switchMap, take, debounceTime, startWith } from 'rxjs/operators';
import { EventType, UIScriptDto } from '@core/services/api-clients';
import { IWidgetEventArgs } from '../models/widget-event-args';
import { IExecutionContext, IExecutionEvent } from 'src/engine-sdk';
import { WidgetType } from '../models/widget-type';
import { IScriptRunnerService } from '../models/iscript-runner.service';

export interface IWidgetScripts {
  [widgetId: string]: { uiScripts?: UIScriptDto[] };
}

export interface EventScripts {
  event: IWidgetEventArgs;
  scripts: UIScriptDto[];
}

@Directive()
// TODO-MP: provide new instruction (below is deprecated)
// 1. Widget root should:
//    a. extend WidgetDirective (NoEventWidget in most cases if has no scripts)
//    b. provide widgets and assign them to this.widgets
//    c. override isWidgetLoaded method if needed
// 2. Widget child should:
//    a. extend WidgetDirective (NoEventWidget when has scripts)
//    b. have parent property assigned
//    c. have widgetId property assigned (to match scripts, no need to override when has no scripts)
//    d. override isWidgetLoaded method if needed
export abstract class WidgetDirective implements AfterViewInit, OnDestroy {
  private _enableDebugLogging: boolean = false;
  private _widgetId: string;
  private _uiScripts: UIScriptDto[] = [];
  private _widgetScripts: IWidgetScripts = {};
  private _children$ = new BehaviorSubject<WidgetDirective[]>([]);
  private _widgetEventsQueue: IWidgetEventArgs[] = [];
  private _isAfterViewInit$ = new BehaviorSubject(false);
  private _isCustomizationMode = false;
  protected _widgetLoadedEvent: IWidgetEventArgs;
  protected get _isWidgetLoaded(): boolean {
    return !!this._widgetLoadedEvent;
  }
  protected _isOnClickEventSupported = false;
  protected _minWidgetsNumber = 0;
  protected _events$ = new Subject<IExecutionEvent>();
  protected _destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() set widgetId(v: string) {
    this._widgetId = v;
    this.widgetScripts = { [this.widgetId]: { uiScripts: this.uiScripts } };
  }
  @Input() set uiScripts(v: UIScriptDto[]) {
    this._uiScripts = v;
    this.widgetScripts = { [this.widgetId]: { uiScripts: this.uiScripts } };
  }
  @Input() ignoreWidgetEvents: boolean = false;
  @Input() set widgetScripts(v: IWidgetScripts) {
    const hasAnyScript = Object.keys(v).length > 0 && Object.values(v).some((x) => x.uiScripts?.length > 0);
    if (!hasAnyScript) return;
    this.rootWidget._widgetScripts = {
      ...this.rootWidget._widgetScripts,
      ...v,
    };
  }

  get isCustomizationMode() {
    if (this.parentWidget != null && this.parentWidget.isCustomizationMode) {
      return true;
    }

    return this._isCustomizationMode;
  }

  @Input() set isCustomizationMode(isCustomizationMode: boolean) {
    this._isCustomizationMode = isCustomizationMode;
  }

  areChildrenLoaded$: Observable<boolean>;
  isWidgetLoaded$: Observable<boolean>;
  widgetRefreshed$: Observable<boolean>;

  get widgetId(): string {
    return this._widgetId;
  }
  get rootWidget(): WidgetDirective {
    if (this.parentWidget) return this.parentWidget.rootWidget;

    return this;
  }
  get widgetScripts(): IWidgetScripts {
    return this.rootWidget._widgetScripts;
  }
  get uiScripts(): UIScriptDto[] {
    return this._uiScripts;
  }

  protected constructor(public parentWidget: WidgetDirective, private _scriptRunnerService: IScriptRunnerService) {
    this.widgetId = this.constructor.name;
    if (this.parentWidget) {
      this.parentWidget.registerChild(this);
    }

    // move initialization to the constructor and wait for ngAfterViewInit
    // thanks to that we can avoid 'read undefined' errors
    this.areChildrenLoaded$ = this.areChildrenLoaded();
    this.isWidgetLoaded$ = this._isAfterViewInit$.pipe(
      takeUntil(this._destroy$),
      filter((x) => x),
      switchMap(() => {
        return combineLatest([this.areChildrenLoaded$, this.isWidgetInitiated()]).pipe(
          takeUntil(this._destroy$),
          map(([areChildrenLoaded, isWidgetLoaded]) => areChildrenLoaded && isWidgetLoaded),
        );
      }),
    );
    this.widgetRefreshed$ = this._isAfterViewInit$.pipe(
      takeUntil(this._destroy$),
      filter((x) => !!x),
      switchMap(() => combineLatest([this.isParentWidgetRefreshed(), this.isWidgetRefreshed()])),
      map(([isParentRefreshed, isWidgetRefreshed]) => this._isWidgetLoaded && (isParentRefreshed || isWidgetRefreshed)),
    );
  }

  ngAfterViewInit(): void {
    this.isWidgetLoaded$
      .pipe(
        takeUntil(this._destroy$),
        startWith(false),
        filter((x) => !!x),
        take(1),
        tap(() => {
          const executionEvent: IExecutionEvent = { eventType: EventType.OnLoaded };

          this._events$.next(executionEvent);
          this.triggerEvent(this.createEventArgs(executionEvent));

          if (this.parentWidget) {
            this.parentWidget.updateChild(this);
          }
        }),
      )
      .subscribe();

    this.widgetRefreshed$
      .pipe(
        filter((x) => !!x),
        tap(() => this.triggerEvent(this.createEventArgs({ eventType: EventType.OnLoaded }))),
      )
      .subscribe();

    this._isAfterViewInit$.next(true);
  }

  ngOnDestroy(): void {
    if (this.parentWidget) {
      this.parentWidget.unregisterChild(this);
    }

    this._destroy$.next(true);
    this._destroy$.complete();
    this._events$.complete();
  }

  @HostListener('click') onWidgetClick(): void {
    if (this._isOnClickEventSupported) {
      const executionEvent: IExecutionEvent = { eventType: EventType.OnClick }

      this._events$.next(executionEvent);
      this.triggerEvent(this.createEventArgs(executionEvent));
    }
  }

  registerChild(widget: WidgetDirective): void {
    this._children$.next([...this._children$.value, widget]);
    if (this._enableDebugLogging) {
      console.log(this.widgetId, 'registerChild', widget.widgetId, this._children$.value);
    }
    if (this.parentWidget) {
      this.parentWidget.updateChild(this);
    }
  }

  unregisterChild(widget: WidgetDirective): void {
    this._children$.next(this._children$.value.filter((x) => x !== widget));

    if (this._enableDebugLogging) {
      console.log(this.widgetId, `unregisterChild`, widget.widgetId, this._children$.value);
    }
    if (this.parentWidget) {
      this.parentWidget.updateChild(this);
    }
  }

  updateChild(_: WidgetDirective): void {
    const children = [...this._children$.value];
    this._children$.next(children);
    if (this._enableDebugLogging) {
      console.log(this.widgetId, 'updateChild', _.widgetId, this._children$.value);
    }
    if (this.parentWidget) {
      this.parentWidget.updateChild(this);
    }
  }

  abstract getWidgetType(): WidgetType;
  protected abstract getExecutionContext(executionEvent: IExecutionEvent): Observable<IExecutionContext>;

  // This method can be overridden to do external stuff on widget loaded
  protected onLoaded(): void { }

  // This method can be overridden to do external stuff on widget changed
  protected onChanged(): void { }

  // This method should be overridden if the logic of determining the load state is more complicated.
  // For this purpose, for example, jQuery can be used
  protected isWidgetInitiated(): Observable<boolean> {
    const subject = new AsyncSubject<boolean>();
    subject.next(true);
    subject.complete();

    return subject.asObservable();
  }

  // This method should be overridden if the logic of determining the refresh state is more complicated.
  // For this purpose, for example, jQuery can be used
  protected isWidgetRefreshed(): Observable<boolean> {
    const subject = new AsyncSubject<boolean>();
    subject.next(false);
    subject.complete();

    return subject.asObservable();
  }

  // This method can be overridden by the parent widget which has knowledge of how to retrieve scripts to run.
  protected getScriptsToRun(events: IWidgetEventArgs[]): Observable<EventScripts[]> {
    const eventsWithScripts = events.filter(
      (e) =>
        this.widgetScripts[e.widget.widgetId]?.uiScripts?.length > 0 &&
        this.widgetScripts[e.widget.widgetId].uiScripts.some((s) => s.eventType == e.executionEvent.eventType),
    );
    const eventsWithoutScripts = events.filter((e) => !eventsWithScripts.includes(e));
    eventsWithoutScripts.filter((e) => e.postCallback).forEach((e) => e.postCallback());

    return of(
      eventsWithScripts.map((e) => {
        const widgetAllScripts = this.widgetScripts[e.widget.widgetId]?.uiScripts ?? [];
        return {
          event: e,
          scripts: widgetAllScripts.filter((x) => x.eventType == e.executionEvent.eventType).sort((a, b) => a.order - b.order),
        };
      }),
    );
  }

  protected triggerEvent(eventArgs: IWidgetEventArgs): void {
    this.tryMarkWidgetAsLoaded(eventArgs);
    if (this.ignoreWidgetEvents) return;
    if (this.isEventTriggeredBeforeLoad(eventArgs) && !this.isEventTypeAllowedBeforeLoad(eventArgs.executionEvent.eventType)) return;
    this.tryRunEventHandlerMethod(eventArgs);

    if (this.parentWidget) {
      if (this._enableDebugLogging) {
        console.log(`${this.parentWidget.widgetId} <- ${this.widgetId}`, EventType[eventArgs.executionEvent.eventType]);
      }
      this.parentWidget.triggerEvent(eventArgs);
    } else {
      this._widgetEventsQueue.push(eventArgs);
      if (this._enableDebugLogging && eventArgs.widget.widgetId == this.widgetId) {
        console.log(`root (${this.widgetId})`, EventType[eventArgs.executionEvent.eventType]);
      }
      if (this._isWidgetLoaded) {
        if (this._enableDebugLogging) {
          console.log(`root (${this.widgetId}) triggers events`, this._widgetEventsQueue);
        }
        const eventsToRun = [...this._widgetEventsQueue.reverse()];
        this._widgetEventsQueue = [];
        this.triggerEventsInternal(eventsToRun).pipe(takeUntil(this._destroy$), take(1)).subscribe();
      }
    }
  }

  protected createEventArgs(executionEvent: IExecutionEvent, postCallback?: () => void): IWidgetEventArgs {
    return {
      executionEvent: executionEvent,
      widget: this,
      postCallback: postCallback,
      timestamp: new Date().getTime(),
    };
  }

  protected isEventTriggeredBeforeLoad(eventArgs: IWidgetEventArgs): boolean {
    return !this._isWidgetLoaded || eventArgs.timestamp < this._widgetLoadedEvent.timestamp;
  }

  protected isEventTypeAllowedBeforeLoad(eventType: EventType): boolean {
    return [EventType.OnLoaded].includes(eventType);
  }

  private tryMarkWidgetAsLoaded(eventArgs: IWidgetEventArgs): void {
    if (eventArgs.widget.widgetId == this.widgetId && eventArgs.executionEvent.eventType == EventType.OnLoaded) {
      this._widgetLoadedEvent = eventArgs;
    }
  }

  private tryRunEventHandlerMethod(eventArgs: IWidgetEventArgs) {
    if (eventArgs.widget.widgetId == this.widgetId) {
      switch (eventArgs.executionEvent.eventType) {
        case EventType.OnLoaded:
          this.onLoaded();
          break;
        case EventType.OnChanged:
          this.onChanged();
          break;
      }
    }
  }

  private isParentWidgetRefreshed(): Observable<boolean> {
    return !!this.parentWidget ? this.parentWidget.widgetRefreshed$ : of(false);
  }

  private areChildrenLoaded(): Observable<boolean> {
    const areChildrenLoadedInternal$ = this._children$.pipe(
      takeUntil(this._destroy$),
      debounceTime(100),
      map((children) => {
        return children.length >= this._minWidgetsNumber && children.every((x) => x._isWidgetLoaded);
      }),
    );
    return areChildrenLoadedInternal$;
  }

  private triggerEventsInternal(events: IWidgetEventArgs[]): Observable<any> {
    if (this.isCustomizationMode) {
      return EMPTY;
    }

    // jak pozbędziemy się tych dyrektyw to poprawić script runner service
    return this.getScriptsToRun(events).pipe(
      switchMap((eventScripts) => {
        return this._scriptRunnerService
          .registerScripts(eventScripts.flatMap((x) => x.scripts))
          .pipe(map(() => eventScripts));
      }),
      switchMap((eventScripts) => {
        return forkJoin(
          eventScripts.map((x) =>
            x.event.widget.getExecutionContext(x.event.executionEvent).pipe(
              take(1),
              map((executionContext) => {
                return {
                  event: x.event,
                  scripts: x.scripts,
                  executionContext: executionContext,
                };
              }),
            ),
          ),
        );
      }),
      switchMap((events) => {
        for (const event of events) {
          if (event.scripts && event.scripts.length > 0) {
            this._scriptRunnerService.runScripts(event.scripts, event.executionContext);
          }
          if (event.event.postCallback && !event.event.isSuppressed) {
            event.event.postCallback();
          }
        }
        return of(true);
      }),
    );
  }
}
