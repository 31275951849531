import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CustomizationsActionTypes, LoadRootCustomizationPanelElement, LoadNestedCustomizationPanelElement, LoadNestedCustomizationPanelElementSuccess, LoadRootCustomizationPanelElementSuccess, LoadCustomizationPreview, LoadCustomizationPreviewSuccess, PublishCustomizations, PublishCustomizationsSuccess } from "./actions";
import { CustomizationsClient, GetCustomizationPreviewQuery, PublishCustomizationsCommand } from "../../services/api-clients";
import { EMPTY, map, mergeMap, of, tap, withLatestFrom } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getTopNavigationStackItem } from "../../navigation/store/selectors";
import { getSchema, getUnpublishedCustomizationChanges } from "./selectors";

@Injectable()
export class CustomizationsEffects {
  loadRootCustomizationPanelElement$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadRootCustomizationPanelElement>(CustomizationsActionTypes.LoadRootCustomizationPanelElement),
        withLatestFrom(this._store.pipe(select(getTopNavigationStackItem))),
        mergeMap(([action, stackItem]) => {
          if (stackItem?.navigationTarget == null)
            return EMPTY;

          if (stackItem.navigationTarget.formId) {
            return this._customizationsClient.getCustomizationPanel("Form", stackItem.navigationTarget.formId)
          } else if (stackItem.navigationTarget.viewId) {
            return this._customizationsClient.getCustomizationPanel("View", stackItem.navigationTarget.viewId)
          } else if (stackItem.navigationTarget.kanbanId) {
            return this._customizationsClient.getCustomizationPanel("Kanban", stackItem.navigationTarget.kanbanId)
          }
        }),
        mergeMap(customizationPanel => [
          new LoadRootCustomizationPanelElementSuccess({ customizationPanel }),
          new LoadCustomizationPreview({
            entityName: customizationPanel.element.schemaId,
            recordId: customizationPanel.element.item.Id,
          })
        ])
      ));

  loadNestedCustomizationPanelElement$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadNestedCustomizationPanelElement>(CustomizationsActionTypes.LoadNestedCustomizationPanelElement),
        map(action => action.payload.customizationElement),
        withLatestFrom(this._store.select(getSchema)),
        mergeMap(([element, schema]) => {
          return this._customizationsClient.getCustomizationPanel(schema[element.schemaId].entityName, element.item.Id).pipe(
            mergeMap(result => of(([element, result])))
          );
        }),
        map(([parentElement, customizationPanel]) => new LoadNestedCustomizationPanelElementSuccess({ parentElement, customizationPanel }))
      ));

  publishCustomizations$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<PublishCustomizations>(CustomizationsActionTypes.PublishCustomizations),
        map(action => action.payload),
        withLatestFrom(this._store.select(getUnpublishedCustomizationChanges)),
        mergeMap(([payload, changes]) => {
          return this._customizationsClient.publishCustomizations(PublishCustomizationsCommand.fromJS({ changes: changes })).pipe(
            mergeMap(result => of(payload))
          );
        }),
        tap((payload) => payload.onSuccess()),
        mergeMap((payload) => [
          new PublishCustomizationsSuccess(),
          new LoadRootCustomizationPanelElement()
        ])
      ));

  loadCustomizationPreview$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<LoadCustomizationPreview>(CustomizationsActionTypes.LoadCustomizationPreview),
        map(action => action.payload),
        withLatestFrom(this._store.select(getUnpublishedCustomizationChanges)),
        mergeMap(([payload, changes]) => {
          return this._customizationsClient.getCustomizationPreview(GetCustomizationPreviewQuery.fromJS({
            entityName: payload.entityName,
            recordId: payload.recordId,
            changes: changes
          }));
        }),
        map(customizationPreviewResult => new LoadCustomizationPreviewSuccess({ customizationPreviewResult }))
      ));

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _customizationsClient: CustomizationsClient,

  ) { }

}
